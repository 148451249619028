import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
Vue.use(ElementUI)
import '@/router/permission'
import Vab from '@/utils/vab'
Vue.use(Vab)
import http from '@/utils/http'
Vue.prototype.$http = http

//引入echarts
import * as echarts from 'echarts'
//vue全局注入echarts
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')
